import Client from './Clients/AxiosClient';
const resource = '/api/Staff';

export default {
  getStaffs(lazyParams, searchFilterbyOutlet, searchFilterbyContact) {
    return Client.get(
      `${resource}/filter-by-outlet/${searchFilterbyOutlet?searchFilterbyOutlet.id:0}`,
      {
        params: {
          skipCount: lazyParams.first,
          takeCount: lazyParams.rows,
          searchFilterbyContact: searchFilterbyContact,
        },
      },
    );
  },
  async createStaff(staff, regAuth) {
    return Client.post(`/api/Authentication/register`, regAuth)
      .then(async function() {
        return await Client.post(`${resource}`, {
          staff: staff,
          counter: staff.counter,
        })
          .then(function(response) {
            return response;
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  },
  // updateStaff(payload) {
  //   return Client.put(`${resource}/Staff/${payload.id}`, payload);
  // },

  async updateStaff(staff) {
    //This is necessary! Check why there is technicalServices key in Staff and remove it!. The json data also need to be minimize.
    // Otherwise, .net will receive null.
    staff.technicalServices = null;
    staff.counterToStaffAdjustmentLogs = [];
    staff.value = null;
    return await Client.put(`${resource}/${staff.id}`, {
      staff: staff,
      counter: staff.counter,
    });
  },
  getCounterGroups(lazyParams) {
    return Client.get(`/api/CounterGroup`, {
      params: {
        skipCount: lazyParams.first,
        takeCount: lazyParams.rows,
        filterGroupbyOutletId: lazyParams.filterGroupbyOutletId,
      },
    });
  },
  async createCounterGroup(counterGroup) {
    // We dont use this because of can't find a consistent algotithm to alter state.counters with new changes
    return await Client.post(`/api/CounterGroup`, {
      counterGroup: counterGroup,
      counters: counterGroup.counters,
      walkinServiceTypes: counterGroup.walkinServiceTypes,
    });
    // return Client.post(`/api/CounterGroup`, { counterGroup: counterGroup, counters: counterGroup.counters, walkinServiceTypes: counterGroup.walkinServiceTypes }).then(async function () {
    //   return await Client.get(`/api/CounterGroup`)
    //     .then(function (response) {
    //       return response;
    //     });
    // });
  },
  async updateCounterGroup(counterGroup, lazyParams) {
    // We dont use this because of can't find a consistent algotithm to alter state.counters with new changes
    // return await Client.put(`/api/CounterGroup`, {counterGroup:counterGroup, counters:counterGroup.counters, walkinServiceTypes:counterGroup.walkinServiceTypes});
    return Client.put(`/api/CounterGroup`, {
      counterGroup: counterGroup,
      counters: counterGroup.counters,
      walkinServiceTypes: counterGroup.walkinServiceTypes,
    }).then(async function() {
      return await Client.get(`/api/CounterGroup`, {
        params: {
          skipCount: lazyParams.first,
          takeCount: lazyParams.rows,
          filterGroupbyOutletId: lazyParams.filterGroupbyOutletId,
        },
      }).then(function(response) {
        return response;
      });
    });
  },

  //Two Layers
  getOutlets() {
    return Client.get(`/api/Outlet`);
  },
  getOutletsOnly(payload) {
    return Client.get(`/api/Outlet/only/${payload.organizationId}`);
  },
  async createOutlet(outlet) {
    return await Client.post(`/api/Outlet`, {
      outlet: outlet,
      totalCounters: outlet.totalCounters,
    });
  },
  async updateOutlet(outlet) {
    return await Client.put(`/api/Outlet/${outlet.id}`, {
      outlet: outlet,
      totalCounters: outlet.totalCounters,
    });
  },

  //Three layers
  async getCounters(payload) {
    return await Client.get(`/api/Counter/by-outlet/${payload.id}`);
  },

  //One layer
  getOrganizations() {
    return Client.get(`/api/Organization`);
  },
  getWalkinServiceTypes() {
    return Client.get(`/api/WalkinServiceType`);
  },
  getStaffPositions() {
    return Client.get(`/api/StaffPosition`);
  },
  // delete(id) {
  //   return Client.delete(`${resource}/${id}`);
  // },

  // MANY OTHER ENDPOINT RELATED STUFFS
};
